import { CardContent, CardHeader, CardTitle } from '@repo/ui/components/Card.js'
import { Logo } from '@repo/ui/components/Logo.js'
import { Spacer } from '@repo/ui/components/Spacer.js'
import { redirect } from 'react-router'
import { z } from 'zod'
import { LoginButton } from '~/components/LoginButton'
import { authenticator } from '~/services/auth.server'
import { actionError, validateFormData } from '~/utils/actions.server'
import { setCookie } from '~/utils/cookie.server'
import { parseQueryParams } from '~/utils/misc'
import type { Route } from './+types/route'
import { LoginSchema } from './routeConfig'

const QueryParamsSchema = z.object({
  redirectTo: z.string().optional()
})

export const loader = async ({ request }: Route.LoaderArgs) => {
  const queryParams = parseQueryParams(request, QueryParamsSchema)
  return { redirectTo: queryParams.redirectTo }
}

export const action = async ({ request }: Route.ActionArgs) => {
  const validation = await validateFormData(request, LoginSchema)

  if (!validation.ok) return actionError(validation)

  const { url, codeVerifier } = await authenticator.generateAuthUrl(
    validation.data.provider
  )
  const headers = new Headers()

  headers.append(
    'set-cookie',
    await setCookie('authCodeVerifier', codeVerifier)
  )
  headers.append(
    'set-cookie',
    await setCookie('loginRedirect', validation.data.redirectTo)
  )
  headers.append(
    'set-cookie',
    await setCookie('timezone', validation.data.timezone)
  )

  return redirect(url, { headers })
}

export default function LoginPage({ loaderData }: Route.ComponentProps) {
  const { redirectTo } = loaderData

  return (
    <>
      <CardHeader className="flex flex-col items-center justify-center gap-2">
        <Logo className="w-40" />
        <Spacer size="sm" />
        <CardTitle className="font-heading text-xl tracking-normal">
          Log in to your account
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-3">
        <LoginButton provider="google" redirectTo={redirectTo} />
        <LoginButton provider="microsoft" redirectTo={redirectTo} />
        <small className="text-muted-foreground text-center w-full block">
          Please use your work email address.
        </small>
      </CardContent>
    </>
  )
}
